<template>
  <div class="row member-card">
    <div class="memberSearch-wrap">
      <form @submit.prevent="setFilters">
        <div class="form-group row">
          <div class="col-xxl-2 col-xl-2 col-lg-6 col-md-6 mb-4">
            <label>System Id</label>
            <input
              v-model="filters.policy_id"
              type="text"
              class="memberSearch-field"
              placeholder="Enter System Id"
            />
          </div>
          <div class="col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-4">
            <label>First Name</label>
            <input
              v-model="filters.first_name"
              type="text"
              class="memberSearch-field"
              placeholder="Enter First Name"
            />
          </div>
          <div class="col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-4">
            <label>Last Name</label>
            <input
              v-model="filters.last_name"
              type="text"
              class="memberSearch-field"
              placeholder="Enter Last Name"
            />
          </div>
          <div
            class="col-xxl-2 col-xl-2 col-lg-6 col-md-6 mb-4"
            v-if="type != 'rep'"
          >
            <label>Rep Code</label>
            <input
              v-model="filters.agent_code"
              type="text"
              class="memberSearch-field"
              placeholder="Enter Rep Code"
            />
          </div>
          <div
            :class="
              type == 'rep'
                ? 'col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-4'
                : 'col-xxl-2 col-xl-2 col-lg-6 col-md-6 mb-4'
            "
          >
            <label>Status</label>
            <select class="memberSearch-field" v-model="filters.status">
              <option value="">All</option>
              <option value="ACTIVE">Active</option>
              <option value="TERMED">Termed</option>
              <option value="WITHDRAWN">Withdrawn</option>
              <option value="PENDING">Pending</option>
            </select>
          </div>
        </div>

        <div class="form-group row">
          <div  :class="  type == 'rep' ? 'col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-4'
                : 'col-xxl-2 col-xl-2 col-lg-6 col-md-6 mb-4'
            "
          >
            <label>State</label>
            <multiselect
              name="state"
              v-model="filters.state"
              placeholder="Select State"
              :options="stateList.map((type) => type.value)"
              :custom-label="
                (opt) => stateList.find((x) => x.value == opt).text
              "
            />
          </div>
          <div class="col-xxl-4 col-xl-4 col-lg-6 col-md-6 mb-4">
            <label>Enrollment Date</label>
            <div class="enrollmentDate-picker">
              <date-picker
                @input="setToDate"
                input-class="memberSearch-field"
                placeholder="From"
                type="date"
                v-model="filters.enrollment_from"
                value-type="format"
                :disabled-date="
                  (date) => {
                    return disabledAfterCurrentDate(date, 'start');
                  }
                "
              ></date-picker>

              <date-picker
                input-class="memberSearch-field"
                placeholder="To"
                type="date"
                v-model="filters.enrollment_to"
                value-type="format"
                :disabled="disableDate"
                :disabled-date="
                  (date) => {
                    return disabledAfterCurrentDate(date, 'end');
                  }
                "
              ></date-picker>
            </div>
          </div>
          <div
            class="col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-4"
            v-if="type != 'rep'"
          >
            <label>View Downline Clients For</label>
            <multiselect
              name="downline"
              v-model="filters.rep_id"
              placeholder="Select Rep"
              :options="downlineReps.map((type) => type.id)"
              :custom-label="
                (opt) =>
                  downlineReps.find((x) => x.id == opt).first_name +
                  ' ' +
                  downlineReps.find((x) => x.id == opt).last_name
              "
              :options-limit="100"
              :internal-search="false"
              :local-search="false"
              @search-change="searchDownlineHandler"
              @open="getDownline"
            />
          </div>
          <div
            class="col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-4"
            v-if="type != 'group'"
          >
            <label>View by Group</label>
            <multiselect
              name="group"
              v-model="filters.group_id"
              placeholder="Select Group"
              :options="groups.map((type) => type.group_id)"
              :custom-label="
                (opt) => groups.find((x) => x.group_id == opt).group_name
              "
              :options-limit="100"
              :internal-search="false"
              :local-search="false"
              @search-change="searchHandler"
              @open="getGroups"
            />
          </div>
          
        </div>

        <div class="form-group row">
          <div class="col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-4">
            <label>Effective Date</label>
            <div class="effective_date">
              <date-picker
                input-class="memberSearch-field"
                placeholder="Effective date"
                type="date"
                v-model="filters.effective_date"
                value-type="format"
              ></date-picker>
            </div>
          </div>

          <div class="col-xxl-3 col-xl-3 col-lg-6 col-md-6">
            <label>Members</label>
            <select class="form-select shadow-none" v-model="filters.type">
              <option value="">All</option>
              <option value="direct">Direct</option>
              <option value="downline">Downline</option>
            </select>
          </div>
          <div class="col-xxl-3 col-xl-3 col-lg-6 col-md-6">
            <multi-selectable-input
              :vmodel.sync="selectedCarriers"
              labelName="Carriers"
              :options="carriers"
              displayLabel="carrier_name"
              formGroupClass="col-xxl-4 mb-3"
              placeholder="Select carriers"
              :optionsLimit="25"
              :activeData.sync="carriers"
              trackBy="carrier_id"
              :isLoading="isCarrierLoading"
              @onInput="getCarrierInput"
              @search="searchCarrier"
            />
          </div>
          <div class="col-xxl-3 col-xl-3 col-lg-6 col-md-6">
            <multi-selectable-input
              :vmodel.sync="selectedPlans"
              labelName="Plans"
              :options="plans"
              displayLabel="plan_name"
              formGroupClass="col-xxl-4 mb-3"
              placeholder="Select Plans"
              :optionsLimit="25"
              :activeData.sync="plans"
              @search="searchPlan"
              trackBy="plan_id"
              :isLoading="isPlanLoading"
              @onInput="getPlanInput"
            />
          </div>
          
        </div>

        <div class="row justify-content-md-end">
        
          <div class="col-xxl-2 col-xl-3">
            <button type="submit" class="univ-btn">Search</button>
          </div>
           <div class="col-xxl-2 col-xl-3">
            <button type="button" class="univ-btn" @click="exportMember"><i class="fas fa-file-export"></i> Export</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import Helper from "@/resource/Helper";
import Api from "@/resource/Api";

export default {
  name: "ClientSearch",
 
  props: {
    filters: {
      required: true,
    },
    type: {
      type: String,
      required: false,
    },
    clear: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      stateList: Helper.getStates(),
      groups: [],
      downlineReps: [],
      disableDate: true,
      carriers: [],
      plans: [],
      policies: [],
      isPlanLoading: false,
      isCarrierLoading: false,
      selectedPlans: [],
      selectedCarriers: [],
      searchPlanQuery: "",
      searchCarrierQuery: "",
    };
  },
  created() {
    this.getGroups();
    this.getDownline();
    this.getCarriers();
    this.getPlans();
  },
  methods: {
    setFilters() {
      this.$emit("onSearch", this.filters);
    },
    clearSearch() {
      this.$emit("onClearSearch");
    },
    searchHandler(query) {
      if (query.length > 2) {
        this.getGroups(query);
      }
    },
    searchDownlineHandler(query) {
      if (query.length > 2) {
        this.getDownline(query);
      }
    },
    getGroups(query = "") {
      let url;
      if (query) {
        url = `v2/get-rep-group-list?size=100&group_name=${query}`;
      } else {
        url = `v2/get-rep-group-list?size=100`;
      }
      Api.get(url).then((res) => {
        this.groups = res.data.data;
      });
    },
    getDownline(query = "") {
      let url;
      if (query) {
        url = `v2/get-rep-downline?size=100&rep_name=${query}`;
      } else {
        url = `v2/get-rep-downline?size=100`;
      }
      Api.get(url).then((res) => {
        this.downlineReps = res.data.data;
      });
    },
    disabledAfterCurrentDate(date, type) {
      const formattedDate = new Date(new Date().setHours(0, 0, 0, 0));
      if (type == "end") {
        if (
          date <
          new Date(new Date(this.filters.enrollment_from).setHours(0, 0, 0, 0))
        ) {
          return true;
        }
      }
      return (
        date >
        formattedDate.setDate(formattedDate.getDate() - +(type == "start"))
      );
    },
    setToDate(date) {
      if (date) {
        this.disableDate = false;
      } else {
        this.filters.enrollment_to = "";
        this.disableDate = true;
      }
    },
    async getPlans() {
      let planFetchUrl = "v2/rep/get-plans";
      planFetchUrl+=`?page_size=25`;
      if (this.searchPlanQuery) {
        planFetchUrl += `&plan_name=${this.searchPlanQuery}`;
        this.isPlanLoading = true;
      }
      
      await Api.get(planFetchUrl)
        .then((res) => {
          this.plans = res.data.data;
        })
        .catch(() => {
          this.plans = [];
        })
        .finally(() => {
          this.isPlanLoading = false;
        });
    },
    async getCarriers() {
      let carrierFetchUrl = "v2/rep/get-carrier";
      if (this.searchCarrierQuery) {
        carrierFetchUrl += `?carrier_name=${this.searchCarrierQuery}`;
        this.isCarrierLoading = true;
      }
      await Api.get(carrierFetchUrl)
        .then((res) => {
          this.carriers = res.data.data;
        })
        .catch(() => {
          this.carriers = [];
        })
        .finally(() => {
          this.isCarrierLoading = false;
        });
    },
    getPlanInput(options) {
      this.filters["plan_id"] = options.map((plan) => plan.plan_id).toString();
    },
    getCarrierInput(options) {
      this.filters["carrier_id"] = options
        .map((carrier) => carrier.carrier_id)
        .toString();
    },
    searchPlan(q) {
      this.searchPlanQuery = q;
      if (q && q.length >= 3) {
        this.getPlans();
      }
    },
    searchCarrier(q) {
      this.searchCarrierQuery = q;
      if (q && q.length >= 3) {
        this.getCarriers();
      }
    },
  async exportMember() {
      let url;
      if (this.filters) {
        let filterUrl = Helper.objToUrlParams(this.filters);
        url = this.$endpoint.EXPORT_MEMBER_LIST_FORMATTED + `?${filterUrl}`;
      } else {
        url = this.$endpoint.EXPORT_MEMBER_LIST_FORMATTED;
      }
      let loader = this.$loading.show();
      await Api
        .get(url)
        .then((res) => {
           window.open(res.data.data.file_url, "_blank");
        })
        .finally(() => {
          loader.hide();
        });
    },
  },
  watch:{
    searchPlanQuery:function(newQuery){
      if(!newQuery){
        this.getPlans();
      }
    },
    searchCarrierQuery:function(newQuery){
      if(!newQuery){
        this.getCarriers();
      }
    },
     clear: function () {
       this.selectedPlans = [];
       this.selectedCarriers = [];
    },
  }
};
</script>
<style scoped>
.effective_date .mx-datepicker {
  width: 100% !important;
}
.form-select:focus{
  border-color:#03C3EC;
}
</style>
