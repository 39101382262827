<template>
  <div class="page-wrapper member-wrap">
    <div class="container-fluid">
      <div class="page-title">
        <h1>View Members - {{ groupName + "(Group)" }}</h1>
        <button class="clear-search" @click="clearData">Clear Search</button>
      </div>
      <client-search
        :filters="filterQuery"
        @onSearch="filterData"
        type="group"
      />

      <div class="row member-card member-card-list">
        <div class="clientsData-wrap" ref="memberListing">
          <div class="top-pagination">
            <pagination :rows.sync="memberLists" :filters="filterQuery" />
          </div>
          <clients-data :memberLists="memberLists" />
          <pagination :rows.sync="memberLists" :filters="filterQuery" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/*eslint-disable*/
import axios from "axios";
import Helper from "@/resource/Helper";
import ClientsData from "@/views/MemberList/partials/ClientsData.vue";
import ClientSearch from "@/views/MemberList/partials/ClientSearch.vue";

const filterData = {
  first_name: "",
  last_name: "",
  agent_code: "",
  status: "",
  enrollment_date: "",
  state: "",
  effective_date: "",
  enrollment_from: "",
  enrollment_to: "",
  rep_id: "",
  group_id: "",
};
export default {
  name: "MemberListByGroup",
  components: {
    ClientSearch,
    ClientsData,
  },
  data: () => ({
    filterQuery: { ...filterData },
    memberLists: [],
    groupName: "",
  }),
  mounted() {
    this.filterQuery.group_id = parseInt(atob(this.$route.params.groupId));
    this.getData(this.filterQuery);
  },
  methods: {
    clearData() {
      this.filterQuery = { ...filterData };
      this.filterQuery.group_id = parseInt(atob(this.$route.params.groupId));
      this.getData(this.filterQuery);
    },
    filterData(filters) {
      this.filterQuery = filters;
      this.getData(this.filterQuery);
    },
    async getData(filters = []) {
      let url;
      let filterUrl = Helper.objToUrlParams(filters);
      url = this.$endpoint.GET_MEMBERS + `?${filterUrl}`;
      let loader = this.$loading.show();
      await axios
        .get(url)
        .then((res) => {
          this.memberLists = res.data;
          this.groupName = `${res.data.records[0].employer_name}`;
        })
        .finally(() => {
          loader.hide();
        });
    },
  },
};
</script>
<style src="@/assets/css/viewclients.css"></style>
